import React from 'react';
import SEO from '../components/organisms/seo';

const NotFoundPage = () => (
  <div className="spacing-top-big">
    <SEO siteTitle="404" />
    <p className="bold-big">Oh no! This page doesn't exist.</p>
  </div>
);

export default NotFoundPage;
